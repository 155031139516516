<template>
    <div>
        <vue-navigation-bar :options="navbarOptions" @vnb-item-clicked='on_item_clicked'></vue-navigation-bar>
        
        <change-password-dialog ref="changePassDlg"></change-password-dialog>
    </div>

</template>

<script>

import settings from '@/backend/LocalSettings';
import ChangePassword_dialog from '@/pages/ChangePassword_dialog';
import eventBus from '@/backend/EventBus';

const left_menu_items = [
                            {
                                right: "pagina_de_licente",
                                type: "link",
                                text: "Licente",
                                path: { name: "licente" },
                                arrowColor: "#659CC8"
                            },

                            {
                                right: "",
                                type: "link",
                                text: "Taskuri",
                                path: { name: "taskuri" },
                                arrowColor: "#659CC8",
                            },

                            {
                                right: "pagina_de_grid_incasari",
                                type: "link",
                                text: "Grid Incasari",
                                path: { name: "grid-incasari" },
                                arrowColor: "#659CC8",
                            },

                            {
                                right: "pagina_de_incasari",
                                type: "link",
                                text: "Incasari",
                                path: { name: "incasari" },
                                arrowColor: "#659CC8",
                            },
                            
                            {
                                right: "pagina_de_istoric_licente",
                                type: "link",
                                text: "Istoric Licente",
                                path: { name: "licente-list" },
                                arrowColor: "#659CC8",
                            },
                            {
                                right: "pagina_de_clienti",
                                type: "link",
                                text: "Clienti",
                                path: { name: "clienti" },
                                arrowColor: "#659CC8",
                            },
                            {
                                right: "pagina_de_locatii",
                                type: "link",
                                text: "Locatii",
                                path: { name: "locatii" },
                                arrowColor: "#659CC8",
                            },

                            {
                                right: "pagina_de_statii",
                                type: "link",
                                text: "Statii",
                                path: { name: "statii" },
                                arrowColor: "#659CC8",
                            },
                            {
                                right: "pagina_de_facturi",
                                type: "link",
                                text: "Facturi",
                                path: { name: "facturi" },
                                arrowColor: "#659CC8",
                            },

                            
];

const right_menu_items = 
    [ {
        type: "button",
        text: 'Fara client',
        path: { name: "clienti" },
        class: "button-red btn-filtru-client",
    },
    {
        type: "button",
        text: "Reset client",
        path: { name: "clienti" },
        class: "button-red btn-clear-client",
        isLinkAction: true,
    },
    {
        type: "link",
        text: "Profil",
        arrowColor: "#659CC8",
        subMenuOptions:[
            {
                right: "administrare_utilizatori",
                type: "link",
                text: "Grupe Utilizatori",
                path: { name: "grupuri-utilizatori" },
                arrowColor: "#659CC8",
                
            },
            
            {
                right: "administrare_utilizatori",
                type: "link",
                text: "Categorii drepturi",
                path: { name: "categorii-drepturi" },
                arrowColor: "#659CC8",
            },
            {
                right: "administrare_utilizatori",
                type: "link",
                text: "Drepturi",
                path: { name: "drepturi" },
                arrowColor: "#659CC8",
                
            },
            {
                right: "administrare_utilizatori",
                type: "link",
                text: "Utilizatori",
                path: { name: "utilizatori" },
                arrowColor: "#659CC8",
            },
            {
                right: "pagina_de_firme",
                type: "link",
                text: "Firme",
                path: { name: "firme" },
                arrowColor: "#659CC8",
            },
            {
                type: "link",
                text: "Schimbare parola",
                path: { name: "signup" },
                isLinkAction: true,
            },
            {
                type: "hr",
            },
            {
                type: "link",
                text: "Iesire cont",
                path: { name: "login" },
                isLinkAction: true,
            }
        ]
}];


export default {
    name: 'Login',
    data () {
        return {
            
            nume_client_selectat:'fara client',
            id_client_selectat: -1,
            user_type: '',
            navbarOptions:{
                elementId: "main-navbar",
                isUsingVueRouter: true,
                mobileBreakpoint: 992,
                brandImagePath: "./",
                brandImage: require("../assets/logo.png"),
                brandImageAltText: "brand-image",
                collapseButtonOpenColor: "#661c23",
                collapseButtonCloseColor: "#661c23",
                showBrandImageInMobilePopup: true,
                ariaLabelMainNav: "Main Navigation",
                tooltipAnimationType: "shift-away",
                tooltipPlacement: "bottom",
                menuOptionsLeft: [],
                menuOptionsRight: []

            }
        
        }
    },
    components: {
        'change-password-dialog': ChangePassword_dialog
    },
    methods:
        {
            get_left_menu_items() {
                var items     = JSON.parse( JSON.stringify( left_menu_items ) );
                
                var filtered  = [];
                
                items.forEach(item => {
                    if( item.right )
                    {
                        if( this.$has_right( item.right ) ) filtered.push( item );
                    } else {
                        filtered.push( item );
                    }
                });
                return filtered;
            },

            get_right_menu_items() {
                var items     = JSON.parse( JSON.stringify( right_menu_items ) );
                var subitems  = items[2].subMenuOptions;
                var filtered  = [];
                subitems.forEach(item => {
                    if( item.right )
                    {
                        if( this.$has_right( item.right ) ) filtered.push( item );
                    } else{
                        filtered.push( item );
                    }
                });
                items[2].subMenuOptions = filtered;
                console.log(items);
                return items;
            },

            

            on_item_clicked(senderText){
                if( senderText == "Reset client"){
                    this.clear_client_selectat();
                }

                if( senderText == 'Schimbare parola') {
                    this.$refs['changePassDlg'].show_me();
                }
                if( senderText == 'Iesire cont') {
                    this.onLogout();
                }
            },
            post: async function(url, args={}){
                this.loadingVisible      = true;
                var             response = await this.$http.post(url, args );
                this.loadingVisible      = false;
                if( settings.verify_response(response) )
                {
                    return response.body;
                }
                else
                {
                    this.$router.push("/");
                }
            },

            change_password: function(){
                this.$refs['changePassDlg'].show_me();
            },

            handleSelect: function(item)
            {
                console.log(item);
            },

            getNumeClientSelectat(){
                return this.id_client_selectat == -1 ? 'Alege un client' : this.nume_client_selectat;
            },


            async onLogout()
            {
                settings.logout();
                this.$router.push("/");
            },

            clear_client_selectat(){
                settings.set_nume_client_selectat("");
                settings.set_id_client_selectat(-1);

                eventBus.$emit('clear-client-event');
            },

            refresh_client(){
                this.nume_client_selectat     = settings.get_nume_client_selectat();
                this.id_client_selectat       = settings.get_id_client_selectat();
                var btn_filtru_client = Array.from(document.getElementsByClassName("btn-filtru-client"));
                
                btn_filtru_client.forEach(btn => {
                    if(this.nume_client_selectat != '' && this.nume_client_selectat != null ){
                        btn.textContent = this.nume_client_selectat;
                    } else {
                        btn.textContent = 'Alege un client';
                    }    
                });
                
            }
        },
    mounted()
    {
        this.user_type                = settings.get_user_type();
        this.refresh_client();
        eventBus.$on('change-client-event', () => {
            this.refresh_client();
        });
        setInterval( this.refresh_client, 1000 );

        this.navbarOptions.menuOptionsLeft   = this.get_left_menu_items();
        this.navbarOptions.menuOptionsRight  = this.get_right_menu_items();
    },
    beforeDestroy() {
        eventBus.$off('change-client-event')
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
.disabled{
    
}
.nav-on-top{
    z-index:1000;
}

.navbar{
    margin-top: 20px;
}

.bg-dark{
    background-color: #152e33 !important;
}

.navbar-dark .navbar-nav .nav-link{
    color: #44b0cc;
    text-transform: uppercase;
    font-size: 12px;
}

.navbar-dark .active .nav-link{
    background-color: #0d7aa3;
    color: white;
}

.btn-filtru-client{
    border:1px solid #007aff;
    background: white;
    color: #007aff;
    font-weight: bold;
    &:hover{
        background: #DCDCDC !important;
    }
}

.btn-clear-client{
    background:#F56C6C;
    color: white;
}

</style>
